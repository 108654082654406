import React, { useState } from 'react';
import classnames from 'classnames';
import TextField from './TextField';
import { InputTypes } from './InputTypes';
import styles from './TextField.module.scss';

export interface ValidatedTextInputProps {
  autoComplete?: string;
  children?: React.ReactNode;
  className?: string;
  dataTestid: string;
  disabled?: boolean;
  field: string;
  forwardedRef?: React.ForwardedRef<HTMLInputElement>;
  id: string;
  inputChildrenClassName?: string;
  inputClasses?: string;
  label?: React.ReactNode;
  labelClassName?: string;
  labelId?: string;
  name?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onFocus?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  renderActions?: () => React.ReactNode;
  role?: React.AriaRole;
  textInputWrapperClass?: string;
  type?: typeof InputTypes[number];
  validate?: (value: string) => React.ReactNode;
  validateOnBlur?: boolean;
  validateOnChange?: boolean;
  value?: string;
  errorMessage?: React.ReactNode;
}

export const ValidatedTextInput = ({
  autoComplete = 'off',
  children,
  errorMessage: externalErrorMessage,
  forwardedRef,
  inputChildrenClassName,
  onBlur,
  onChange,
  onFocus,
  renderActions,
  textInputWrapperClass,
  type = 'text',
  validate,
  validateOnBlur = true,
  validateOnChange = false,
  value: externalValue,
  ...rest
}: ValidatedTextInputProps): JSX.Element => {
  const [value, setValue] = useState('');
  const [errorMessage, setErrorMessage] = useState<React.ReactNode>(null);
  // This check is needed because useState only sets default state on the first render.
  // At this point, the externalValue from external data sources has not had a chance
  // to get passed down as a prop. It also keeps local state in sync with external state.
  if (externalValue !== undefined && value !== externalValue) {
    setValue(externalValue);
  }

  if (
    externalErrorMessage !== undefined &&
    errorMessage !== externalErrorMessage
  ) {
    setErrorMessage(externalErrorMessage);
  }
  const [isFocused, setIsFocused] = useState(false);
  const classes = classnames(textInputWrapperClass, styles['input-wrapper'], {
    [styles['input-focused']]: isFocused,
    [styles['input-error']]: errorMessage,
  });

  const errorClasses = classnames([styles['input-error-description']]);
  const inputChildrenClasses = classnames(
    styles['input-children'],
    inputChildrenClassName
  );

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChange?.(e);
    if (validateOnChange && validate) {
      setErrorMessage(validate(e.target.value));
    }
  };

  const onBlurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    onBlur?.(e);
    if (validateOnBlur && validate) {
      setErrorMessage(validate(e.target.value));
    }
  };

  const onFocusHandler = () => {
    setIsFocused(true);
    onFocus?.();
  };

  return (
    // Below error should be fixed when TextField is converted to typescript
    // @ts-expect-error FIXME: expect children prop
    <TextField {...rest}>
      {(inputProps: React.HTMLProps<HTMLInputElement>) => (
        <>
          <div className={styles['text-input-with-actions']}>
            <div className={classes}>
              <div className={styles['input-inner-flex']}>
                {children && (
                  <span className={inputChildrenClasses}>{children}</span>
                )}
                <input
                  {...inputProps}
                  ref={forwardedRef}
                  autoComplete={autoComplete}
                  onBlur={onBlurHandler}
                  onChange={onChangeHandler}
                  onFocus={onFocusHandler}
                  type={type}
                  value={externalValue || value}
                />
              </div>
            </div>
            {renderActions && renderActions()}
          </div>
          <div className={errorClasses}>{errorMessage}</div>
        </>
      )}
    </TextField>
  );
};
